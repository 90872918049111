import React from "react";
import styled from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import ArrowLink from "../shared/sub/ArrowLink";
import { P, A, H2, Container } from "../../util/standard";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../util/constants";
import { Link } from "../../util/types";
import BlockContent from "@sanity/block-content-to-react";

interface Props {
  textHeader: string | null | undefined;
  subHeader: string | null | undefined;
  subText: string | null | undefined;
  contentImage?: FluidObject;
  links?: Array<Link>;
  isMobileScreenWidth?: boolean;
  invertedLayout?: boolean;
}

const ContentBlockWrapper = styled(Container)`
  width: 80%;
  max-width: 1000px;
  margin: 100px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    grid-gap: 0;
    flex-wrap: wrap;
    margin: 75px auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 25px auto;
  }
`;

const ImageWrapper = styled(Container)<{ isMobile?: boolean }>`
  ${({ isMobile }) => isMobile && "display: none;"}
  width:100%;
  max-height: 400px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 25px auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 200px;
  }
`;

const TextWrapper = styled(Container)`
  width: 100%;
  display: block;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 25px auto;
    padding: 0px 10%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  }
`;

const ContentImg = styled(Img)`
  height: 100%;
  width: 100%;
`;

const SubHeader = styled(P)`
  margin-bottom: 10px;
`;

const SubText = styled(BlockContent)`
  p {
    margin-bottom: 30px;
    margin-top: 10px;
    width: 80%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    p {
      width: 100%;
    }
  }

  img {
    max-width: 600px;
  }
`;

const StyledH2 = styled(H2)`
  color: black;
  font-weight: 700;
`;

function AboutBlock(props: Props) {
  const {
    textHeader,
    subHeader,
    subText,
    contentImage,
    links,
    invertedLayout,
  } = props;

  return (
    <ContentBlockWrapper>
      {!invertedLayout && (
        <ImageWrapper>
          <ContentImg
            fluid={contentImage}
            alt={contentImage?.asset?.title ?? "about-content-image"}
          />
        </ImageWrapper>
      )}
      <TextWrapper>
        <SubHeader>{subHeader}</SubHeader>
        <StyledH2 color="black">
          {textHeader ?? "Warriors Community Foundation"}
        </StyledH2>

        <SubText
          fontFamily="light"
          blocks={subText}
          projectId={process.env.GATSBY_SANITY_PROJECT_ID}
          dataset={process.env.GATSBY_SANITY_DATASET}
        />
        {links?.map((link: Link, index) => {
          if (index <= 0) {
            return <ArrowLink key={link._key} link={link} />;
          } else {
            return (
              <ArrowLink
                key={link._key}
                link={link}
                margin="10px 0px 0px 0px"
              />
            );
          }
        })}
      </TextWrapper>
      {invertedLayout && (
        <ImageWrapper>
          <ContentImg
            fluid={contentImage as FluidObject}
            alt={contentImage?.asset?.title ?? "about-content-image"}
          />
        </ImageWrapper>
      )}
    </ContentBlockWrapper>
  );
}

export default AboutBlock;
