import React, { useState } from "react";
import Img from "gatsby-image";
import styled from "styled-components";

import { Container, H2, P } from "../../util/standard";
import {
  colors,
  icons,
  IPAD_PRO_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../util/constants";
import { Maybe, SanityDownloadableFile } from "../../../graphql-types";

interface Props {
  data: Maybe<SanityDownloadableFile>[];
}

const ContentSection = styled.div`
  display: block;
  width: 65%;
  margin: 100px auto;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 50px auto;
  }
`;

const ImageWrapper = styled.div`
  height: 200px;
  &:hover {
    cursor: pointer;
    filter: brightness(0.6);
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    height: 150px;
  }

  transition: filter 0.2s;
`;

const StyledImage = styled(Img)`
  width: 100%;
  height: 100%;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  grid-gap: 20px;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LoadMoreButton = styled.button`
  display: block;
  color: ${colors.green};
  background: none;
  border: none;
  text-decoration: underline;
  margin: 30px auto;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledH2 = styled(H2)`
  font-weight: 700;
`;

const DownloadPDF = (props: Props) => {
  const initialSettings = {
    numberToShow: 12,
    loadMoreNumber: 6,
  };
  const { data } = props;
  const [numberToShow, setNumberToShow] = useState(
    initialSettings.numberToShow
  );

  return (
    <ContentSection>
      <StyledH2>Creative Writing</StyledH2>
      <GridWrapper>
        {data &&
          data?.slice(0, numberToShow).map(image => {
            if (image == null) return null;
            return (
              <Link href={image.fileObject?.asset?.url as string} target="_blank" key={image._key}>
                <ImageWrapper>
                  <StyledImage
                    fluid={image.documentImage?.asset?.fluid}
                    alt={
                      image.documentImage?.asset?.title ??
                      "Gallery-image-thumnbail"
                    }
                  />
                </ImageWrapper>
                <Container
                  justifyContent="center"
                  width="100%"
                  alignItems="center"
                  cursor="pointer"
                >
                  <P>{image.fileName}</P>
                  <Container margin="auto 0 auto 10px" width="auto">
                    <img src={icons.downloadDark} width="25px" height="25px" />
                  </Container>
                </Container>
              </Link>
            );
          })}
      </GridWrapper>

      {data && data.length >= numberToShow && (
        <LoadMoreButton
          onClick={() =>
            setNumberToShow(pre => pre + initialSettings.loadMoreNumber)
          }
        >
          Load More
        </LoadMoreButton>
      )}
    </ContentSection>
  );
};
export default DownloadPDF;
