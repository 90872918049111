import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Carousel } from "antd"

import { BackGroundTexture, Container, H1, H2, P } from '../../util/standard'
import { icons, TABLET_BREAKPOINT, MOBILE_BREAKPOINT, colors, LAPTOP_BREAKPOINT } from '../../util/constants'
import { SanityTestimonial } from '../../../graphql-types'


interface Props {
  width?: string;
  margin?: string;
}


const Wrapper = styled.div<{ width: string; margin: string; }>`
  position: relative;
  width: 100%;
  background-color: ${colors.blue};
  margin:100px auto;

  ${({ width }) => width && `width: ${width}`};
  ${({ margin }) => margin && `margin: ${margin}`};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin:40px auto;
  }
`

const TestimonialsWrapper = styled(Container)`
  width: 80%;
  margin: 0 auto;
  padding: 10px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    padding: 60px 30px 30px 30px;
  }
    
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    padding: 30px 30px 36px 30px;
  }
`

const StyledH2 = styled(H2)`
  font-size: 24px;
  min-width: 240px;
  color: ${colors.white};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 0px;
  }
`

const TextWrapper = styled.div`
 margin: 90px auto 60px auto;
 width: 20%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  margin:0;
  }
`

const CarouselWrapper = styled.div`
 margin: 90px auto 50px auto;
 width: 45%;
 position: relative;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: 0px;
    display:flex;
    flex-direction:column-reverse;
  }

`

const OpenQuote = styled.img`
width: 25px;
margin-bottom: 0px;
position: absolute;
top: 35px;
left: -10px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
         display: none !important;

  }
`
const CloseQuote = styled.img`
width: 25px;
position: absolute;
bottom: 35px;
right: -10px;
margin-bottom: 0px;

@media only screen and (min-width: ${LAPTOP_BREAKPOINT}px) {
       bottom: 80px;
  }

 @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none !important;
  }
`

const CarouselNav = styled.div`
@media (max-width:${TABLET_BREAKPOINT}px){
    display: flex;
    gap: 25px;
    margin-top:20px;
}
@media (max-width:${MOBILE_BREAKPOINT}px){
  margin: 20px 0;
}
`
const LeftArrow = styled.img`
position: absolute;
top: 35%;

&:hover{
  cursor:pointer;
}
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 0;
    position: initial;
  }

`

const RightArrow = styled.img`
position: absolute;
top: 35%;
right: 0px;

&:hover{
  cursor:pointer;
}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    top: 0;
    right:auto;
    position: initial;
  }
`

const Statement = styled(P)`
padding: 40px;

@media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  padding: 0px;
}
`

const QuoteWrapper = styled.div`
display: block !important;
position:relative;
width: 80% !important;
height: 200px;
margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100% !important;
    height: auto;
  }

`
const StyledCarousel = styled(Carousel)`
`

const Testimonials = (props: Props) => {

  const { sanityTestimonial } = useStaticQuery(graphql`
  {
    sanityTestimonial {
      testimonialTitle
      testimonialText
      testimonialStatement
    }
  }  
  `)

  const { width, margin } = props
  const { testimonialTitle, testimonialText, testimonialStatement } = sanityTestimonial

  const carouselSlider = useRef(null)

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    draggable: true,
  }

  return (
    <Wrapper width={width} margin={margin}>
      <BackGroundTexture textureVisible />
      <TestimonialsWrapper>
        <TextWrapper>
          <P color="white" fontFamily="light">{testimonialTitle}</P>
          <StyledH2>{testimonialText}</StyledH2>
        </TextWrapper>
        <CarouselWrapper>
          <StyledCarousel {...carouselSettings} ref={carouselSlider} >
            {testimonialStatement.map((testimonial: SanityTestimonial, index: number) => (
              <QuoteWrapper key={index}>
                <OpenQuote src={icons.openQuote} alt='testimonial-quotation-mark-icon' />
                <Statement color="white" fontFamily="light">{testimonial}</Statement>
                <CloseQuote src={icons.closeQuote} alt='testimonial-quotation-mark-icon' />
              </QuoteWrapper>
            ))}
          </StyledCarousel>
          <CarouselNav>
            <div><LeftArrow src={icons.leftArrow} onClick={() => { carouselSlider?.current?.prev() }} alt='carousel-left-arrow-button' /></div>
            <div><RightArrow src={icons.rightArrow} onClick={() => { carouselSlider?.current?.next() }} alt='carousel-right-arrow-button' /></div>
          </CarouselNav>
        </CarouselWrapper>
      </TestimonialsWrapper>
    </Wrapper>
  )
}

export default Testimonials
