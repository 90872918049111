import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../graphql-types";

import Hero from "../components/shared/Hero";
import AboutBlock from "../components/shared/AboutBlock";
import Testimonials from "../components/shared/Testimonials";
import DonateBlock from "../components/shared/DonateBlock";
import Video from "../components/shared/sub/Video";
import Gallery from "../components/shared/Gallery";
import SEO from "../components/shared/Seo";
import DownloadPDF from "../components/shared/downloadPDF";

export const query = graphql`
  query programsQuery($slug: String) {
    allSanityPrograms(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          slug {
            current
          }
          aboutBlock {
            contentSubtext {
              _rawContent
            }
            contentSubHeader
            contentLinks {
              url
              linkText
              _key
            }
            contentImage {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
                title
              }
            }
            contentHeader
          }
          hero {
            heroText
            heroImage {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
              }
            }
          }
          programPDF {
            _key
            fileName
            documentImage {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
              }
            }
            fileObject {
              asset {
                url
              }
            }
          }
          programGallery {
            images {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
                title
              }
              _key
            }
          }
          programPreview {
            programTitle
            programSubText
            programCategory
            programImage {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
                title
              }
            }
          }
          programVideo {
            videoSourceLink
            videoThumbnail {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                }
                title
              }
            }
          }
          seoBlock {
            pageDescription
            pageKeyWords
            pageTitle
            ogImage {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`;
interface Props extends PageProps {
  data: Query;
}

function ProgramInner({ data }: Props) {
  const {
    allSanityPrograms: {
      edges: [{ node: program }],
    },
  } = data;
  return (
    <div>
      <SEO seoData={program?.seoBlock} slug={program?.slug?.current} />
      <Hero
        heroText={program.hero.heroText}
        heroImage={program.hero.heroImage}
        blockVisible
        blockTextOverwrite={program.aboutBlock.contentHeader}
      />
      <AboutBlock
        invertedLayout
        textHeader={program.aboutBlock.contentHeader}
        subHeader={program.aboutBlock.contentSubHeader}
        subText={program.aboutBlock.contentSubtext._rawContent}
        contentImage={program.aboutBlock.contentImage.asset.fluid}
        links={program.aboutBlock.contentLinks}
      />
      {program.programVideo && (
        <Video
          videoSource={program.programVideo.videoSourceLink}
          thumbnail={program.programVideo.videoThumbnail}
        />
      )}

      {program.programGallery !== null &&
      program.programGallery.images.length >= 1 ? (
        <Gallery images={program.programGallery} />
      ) : null}
      {program.programPDF && program.programPDF.length >= 1 && (
        <DownloadPDF data={program.programPDF} />
      )}
      <Testimonials />
      <DonateBlock />
    </div>
  );
}

export default ProgramInner;
